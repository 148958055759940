<template>
  <div class="anketa-2cols styles">
    <div v-html="title" class="title"></div>
    <div class="top">
      <VueScrollPicker
          v-if="options"
          :options="options"
          v-model="modelValue"
          empty="Error"
          placeholder="-"
      />
    </div>
    <primary-button
        @click="saveLastStep(button.link); events();"
        :disabled="disabled"
        :text="button.text"
        :link="button.link"/>
  </div>

</template>

<script>
import StyleBlock from "@/components/Anketa/StyleBlock";
import {useHeaderStore} from '@/stores/header'
import anketaMixin from "@/mixins/anketaMixin";
import {useAnketaStore} from "@/stores/anketa";
import {ref, toRaw} from "vue";
import topData from "@/data/anketa/topData";
import headerMixin from "@/mixins/headerMixin";
import selectedMixin from "@/mixins/selectedMixin";
import {useSettingsStore} from "@/stores/settings";
import {useApiStore} from "@/stores/api";

export default {
  name: 'TopQuestionView',
  components: {StyleBlock},
  mixins: [anketaMixin, headerMixin, selectedMixin],
  props: ['header'],
  setup() {
    const {setValue, getStepData, steps, saveLastStep} = useAnketaStore();
    let modelValue = ref(getStepData(steps.step8))
    const {setHeaderData} = useHeaderStore();
    const settings = useSettingsStore();
    const api = useApiStore();
    return {
      api, setHeaderData, setValue, saveLastStep,
      getStepData, steps, modelValue, settings
    };
  },
  watch: {
    modelValue(newVal) {
        this.select(newVal)
        this.disabled = newVal == null;
    }
  },
  methods: {
    async events() {
      await this.api.onStageEvent('onFormStage', 'question_9');
    }
  },
  created() {
    this.datas = this.settings.getSetting(this.stepId)
    Object.keys(this.datas).forEach(key => {
      this.options.push({
        value: this.datas[key].value,
        name: this.datas[key].name.text,
      });
    });
  },
  mounted() {
    if (this.modelValue == null) {
      this.disabled = true
    }
  },
  data() {
    return {
      stepId: 'top_size',
      title: "<span>Выбери, какой у тебя размер верха:</span>",
      button: {
        text: 'Продолжить',
        link: 'anketa.step9',
      },
      step_number: "step8",
      options: [],
    }
  }
}
</script>
<style scoped>


</style>
