<template>
  <div class="anketa-2cols">
    <span class="title">{{ title}}</span>
    <div>
      <EyeBlock :data="datas" @isSelected="isSelected()"></EyeBlock>
    </div>
    <primary-button
        @click="saveStep(button.link); events();"
        :disabled="disabled"
        :text="button.text"
        :link="button.link" />
  </div>
</template>

<script>
import EyeBlock from "@/components/Anketa/EyeBlock";
import eyeData from "@/data/anketa/eyeData";
import { useHeaderStore } from '@/stores/header'
import headerMixin from "@/mixins/headerMixin";
import {useSettingsStore} from "@/stores/settings";
import selectedMixin from "@/mixins/selectedMixin";
import {useApiStore} from "@/stores/api";

export default {
  name: 'EyeQuestionView',
  components: {EyeBlock},
  mixins: [headerMixin, selectedMixin],
  props: ['header'],
  setup() {
    const {setHeaderData} = useHeaderStore();
    const settings = useSettingsStore();
    const api = useApiStore();
    return {setHeaderData, settings,api};
  },
  methods:{
    async events() {
      await this.api.onStageEvent('onFormStage', 'question_3');
    }
  },
  data(){
    return {
      stepId: 'eye_color',
      title: 'А какой у тебя цвет глаз?',
      button:{
        text: 'Продолжить',
        link: 'anketa.step3',
      },
    }
  }
}
</script>
