<template>
  <div class="header">
    <a v-if="!header.backLink" @click="handleBackClick(); event('return')">
      <div class="back" v-if="header.back"></div>
    </a>

    <a v-else @click="$router.push({name: header.link})">
      <div class="back"></div>
    </a>

    <span class="title">{{ header.title }}
    </span>
    <div class="link" v-if="!header.removeClose"
         @click="$router.push({name:'home'});event('close');appEvent('onAppClose')">

    </div>
  </div>
</template>

<script>
import {useHeaderStore} from '@/stores/header'
import {useRoute} from "vue-router";
import appMixin from "@/mixins/appMixin";
import router from "@/router";
import {mapState} from "pinia";
import {useUserStore} from "@/stores/users";
import {useApiStore} from "@/stores/api";

export default {
  name: 'Header',
  mixins: [appMixin],
  setup() {
    const store = useHeaderStore()
    const {header} = store;
    const route = useRoute()
    const api = useApiStore();
    return {header, route, api}
  },
  computed: {
    ...mapState(useUserStore, ['user', 'subscribed']),
  },
  methods: {

    async event(actionName) {
      const getPageType = (routeName) => {
        return this.routeMapping[routeName] || 'UNKNOWN';
      };
      const getPageName = (routeName) => {
        return this.pageMapping[routeName] || null;
      };

      let data = {
        "action": actionName,
        "page": getPageType(this.route.name),
      };

      let pageName = getPageName(this.route.name)
      if (pageName){
        data.stage = pageName;
      }

      try {
        await this.api.commonEvent('onWebViewAction', data);
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    },

    handleBackClick() {
      if (this.$route.name === 'anketa.completion') {
        return router.push(`/?clientHash=${this.user.hash}&clientName=${this.user.name}&subscribed=${this.subscribed}`)
      } else {
        this.$router.go(-1)
      }
    }
  },
  data() {
    return {
      routeMapping: {
        'home': 'FORM',          // Пример: главная страница -> FORM
        'init': 'FORM',          // Пример: страница инициализации -> FORM
        'personal': 'STYLECARD', // Личная карта стиля -> STYLECARD
        'styles': 'COLLECTION',  // Образы от стилистов -> COLLECTION
        'other': 'IMAGES',   // Все образы -> COLLECTION
        'compilation': 'IMAGES', // Подборка -> IMAGES
        'otherCompilation': 'IMAGES', // Другая подборка -> IMAGES
        'policy': 'PREVIEW',     // Политика -> PREVIEW
        'anketa': 'FORM',        // Анкета -> FORM
        'anketa.fillout': 'FORM', // Заполнение анкеты -> FORM
        'anketa.step1': 'FORM',  // Шаг 1 анкеты -> FORM
        'anketa.step2': 'FORM',  // Шаг 2 анкеты -> FORM
        'anketa.step3': 'FORM',  // Шаг 3 анкеты -> FORM
        'anketa.step4': 'FORM',  // Шаг 4 анкеты -> FORM
        'anketa.step5': 'FORM',  // Шаг 5 анкеты -> FORM
        'anketa.step6': 'FORM',  // Шаг 6 анкеты -> FORM
        'anketa.step7': 'FORM',  // Шаг 7 анкеты -> FORM
        'anketa.step8': 'FORM',  // Шаг 8 анкеты -> FORM
        'anketa.step9': 'FORM',  // Шаг 9 анкеты -> FORM
        'anketa.completion': 'FORM', // Завершение анкеты -> FORM
      },
      pageMapping: {
        'anketa.fillout': 'start', // Заполнение анкеты -> FORM
        'anketa.step1': 'question_1',  // Шаг 1 анкеты -> FORM
        'anketa.step2': 'question_2',  // Шаг 2 анкеты -> FORM
        'anketa.step3': 'question_3',  // Шаг 3 анкеты -> FORM
        'anketa.step4': 'question_4',  // Шаг 4 анкеты -> FORM
        'anketa.step5': 'question_5',  // Шаг 5 анкеты -> FORM
        'anketa.step6': 'question_6',  // Шаг 6 анкеты -> FORM
        'anketa.step7': 'question_7',  // Шаг 7 анкеты -> FORM
        'anketa.step8': 'question_8',  // Шаг 8 анкеты -> FORM
        'anketa.step9': 'question_9',  // Шаг 9 анкеты -> FORM
        'anketa.completion': 'finish', // Завершение анкеты -> FORM
      }
    };
  }
}
</script>


<style scoped>

</style>