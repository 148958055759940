<template>
  <div class="anketa-2cols styles">
    <div v-html="title"></div>
    <div class="styles-block">
      <StyleBlock :data="datas"></StyleBlock>
    </div>
    <primary-button
        @click="saveLastStep(button.link); events();"
        :text="button.text"
        :link="button.link"/>
  </div>
</template>

<script>
import StyleBlock from "@/components/Anketa/StyleBlock";
import {useHeaderStore} from '@/stores/header'
import headerMixin from "@/mixins/headerMixin";
import {useSettingsStore} from "@/stores/settings";
import {useAnketaStore} from "@/stores/anketa";
import {useApiStore} from "@/stores/api";

export default {
  name: 'StyleQuestionView',
  components: {StyleBlock},
  mixins: [headerMixin],
  props: ['header'],
  setup() {
    const {setHeaderData} = useHeaderStore();
    let {saveLastStep} = useAnketaStore()
    const settings = useSettingsStore();
    const api = useApiStore();
    return {setHeaderData, settings,saveLastStep,api};
  },
  mounted() {
    this.datas = this.settings.getSetting('style')
  },
  methods: {
    async events() {
      await this.api.onStageEvent('onFormStage', 'question_7');
    }
  },
  data() {
    return {
      title: "<span class='title'>Какой стиль ты предпочитаешь?</span>" +
          "<span class='description'>Выбери не более 2-х</span>",
      button: {
        text: 'Продолжить',
        link: 'anketa.step7',
      },
      datas: null,
    }
  }
}
</script>
