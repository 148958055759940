export default {
    methods: {
        appEvent(eventName, data) {
            try {
                let sendData = {event: eventName}

                if (data) {
                    sendData.data = data
                }

                if (window.navigator.userAgent.includes('Android')) {
                    ostinApp.postMessage(JSON.stringify(sendData))

                } else if (window.navigator.userAgent.includes('iPhone')
                    || window.navigator.userAgent.includes('iPad')
                    || window.navigator.userAgent.includes('iPod')) {
                    window.webkit.messageHandlers.ostinApp.postMessage(JSON.stringify(sendData));
                }

            } catch (e) {
                console.log('error in AppEvents')
            }

        }
    },
}