<template>
  <div class="anketa-2cols">
    <span class="title" v-html="title"></span>
    <div>
      <FigureBlock
          :data="datas"
          @isSelected="isSelected()"
      />
    </div>
    <primary-button
        @click="saveStep(button.link); events();"
        :disabled="disabled"
        :text="button.text"
        :link="button.link"/>
  </div>
</template>

<script>
import FigureBlock from "@/components/Anketa/FigureBlock";
import {useHeaderStore} from '@/stores/header'
import headerMixin from "@/mixins/headerMixin";
import {useSettingsStore} from "@/stores/settings";
import selectedMixin from "@/mixins/selectedMixin";
import {useApiStore} from "@/stores/api";

export default {
  name: 'FigureQuestionView',
  components: {FigureBlock},
  mixins: [headerMixin, selectedMixin],
  props: ['header'],
  setup() {
    const {setHeaderData} = useHeaderStore();
    const settings = useSettingsStore();
    const api = useApiStore();
    return {setHeaderData, settings,api};
  },
  mounted() {
    this.datas = this.settings.getSetting('body_type')
  },
  methods: {
    async events() {
      await this.api.onStageEvent('onFormStage', 'question_5');
    }
  },
  data() {
    return {
      stepId: 'body_type',
      title: "Выбери, какой у тебя тип фигуры:",
      button: {
        text: 'Продолжить',
        link: 'anketa.step5',
      },
      datas: null,
    }
  }
}
</script>
